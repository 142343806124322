<template>
  <section>
    <FormulateForm name="formClient">
      <b-card-actions
        :show-loading="loading"
        action-collapse
        :title="`${$t('Ficha Cadastral')}${person.id ? ` #${person.id}` : ''}`"
      >
        <customer-form
          ref="clientForm"
          :person="person"
          :is-read-only="isReadOnly"
          :is-edit="isEdit"
          @clean-person-data="cleanPersonTypeData"
          @show-price-table="onShowPriceTableSidebar"
        />
      </b-card-actions>

      <b-card-actions
        :show-loading="loading"
        action-collapse
        :title="'Características'"
      >
        <!-- each tab has it your own FomulateForm -->
        <b-tabs fill>
          <b-tab :title="$t('Endereços')">
            <e-address-tab
              :is-read-only="isReadOnly"
              person="Customer"
              :addresses="getAddresses"
              @add-address="(formData) => addItem('addresses', formData)"
              @update-address="(formData) => udpateItem('addresses', formData)"
              @remove-address="(id) => removeItem('addresses', id)"
            />
          </b-tab>

          <b-tab :title="$t('Contatos')">
            <e-contact-tab
              :is-read-only="isReadOnly"
              :contacts="getContacts"
              person="Customer"
              @add-contact="(formData) => addItem('contacts', formData)"
              @update-contact="(formData) => udpateItem('contacts', formData)"
              @remove-contact="(id) => removeItem('contacts', id)"
            />
          </b-tab>

          <b-tab :title="$t('Documentos')">
            <files-form
              :files="files"
              :is-read-only="isReadOnly"
              @set-files="setFiles"
              @remove-file="removeFile"
            />
          </b-tab>

          <b-tab :title="$t('Histórico')">
            <history-tab :customer-id="person.id" />
          </b-tab>
        </b-tabs>
      </b-card-actions>
    </FormulateForm>

    <price-table-sidebar
      ref="pricetable_sidebar"
      @save="() => fetchPriceTables()"
    />

    <e-crud-actions
      :show-save="!isReadOnly"
      :busy="loading"
      @save="saveClient"
      @cancel="onCancel"
    />
  </section>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import { EAddressTab, EContactTab } from '@/views/components/tabs'
import { mapActions, mapGetters, mapState } from 'vuex'
import { alerts } from '@/mixins'
import ECrudActions from '@/views/components/ECrudActions.vue'
import HistoryTab from './components/client-maintain-tabs/HistoryTab.vue'
import PriceTableSidebar from '../price-table/PriceTableSidebar.vue'
import CustomerForm from './components/CustomerForm.vue'
import FilesForm from './components/client-maintain-tabs/FilesForm.vue'

export default {
  name: 'ClientMaintain',
  components: {
    BCardActions,
    BTabs,
    BTab,
    HistoryTab,
    EAddressTab,
    EContactTab,
    ECrudActions,
    PriceTableSidebar,
    CustomerForm,
    FilesForm,
  },

  mixins: [alerts],

  props: {
    isReadOnly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      callbackRouteName: 'client-list',
      customerSaved: null,
    }
  },

  computed: {
    ...mapState('pages/sale/clients/clientMaintain', {
      person: 'person',
      attributes: 'attributes',
      stFiles: 'files',
      stFilesDeleted: 'filesDeleted',
    }),
    ...mapGetters('pages/sale/clients', ['customerClassificationsOptions']),
    ...mapGetters('pages/sale/clients/clientMaintain', ['getAddresses', 'getContacts']),
    ...mapGetters('common/priceTables', ['getComboPriceTables']),

    isEdit() {
      return !!this.$route.params.id
    },
    files: {
      get() {
        return this.stFiles
      },
      set(val) {
        this.setFiles(val)
      },
    },
  },

  mounted() {
    this.fetchPriceTables()
    this.fetchCustomerClassifications()

    if (this.isEdit) {
      this.loadClient()
    }

    if (this.$route.params.callbackRouteName) {
      this.callbackRouteName = this.$route.params.callbackRouteName
    }
  },

  methods: {
    ...mapActions('common/priceTables', ['fetchPriceTables']),
    ...mapActions('pages/sale/clients', ['fetchCustomerClassifications']),
    ...mapActions('pages/sale/clients/clientMaintain', [
      'cleanState',
      'cleanPersonTypeData',
      'addAttributeItem',
      'removeAttributeItem',
      'updateAttributeItem',
      'fetchClientById',
      'setFiles',
      'deleteLocalFile',
      'uploadDocuments',
      'deleteDocuments',
    ]),
    ...mapActions('pages/sale/order/orderMaintain', {
      stOrderMaintainFetchCustomerById: 'fetchCustomerById',
    }),

    async addItem(itemName, formData) {
      try {
        await this.addAttributeItem({ itemName, formData })
      } catch (err) {
        this.showError({ message: err.message })
      }
    },

    async udpateItem(itemName, formData) {
      try {
        await this.updateAttributeItem({ itemName, formData })
      } catch (err) {
        this.showError({ message: err.message })
      }
    },

    async removeItem(itemName, id) {
      try {
        await this.removeAttributeItem({ itemName, id })
      } catch (err) {
        this.showError({ message: err.message })
      }
    },

    async loadClient() {
      try {
        this.loading = true
        await this.fetchClientById({
          isReadOnly: this.isReadOnly,
          customerId: this.$route.params.id,
        })
      } catch (err) {
        this.showError({ message: err.message })
      } finally {
        this.loading = false
      }
    },

    onShowPriceTableSidebar() {
      this.$refs.pricetable_sidebar.show()
    },

    async saveClient() {
      if (!this.$refs.clientForm.validate()) {
        this.showInvalidDataMessage({ message: '<b>Verifique a ficha cadastral</b>' })
        return
      }

      try {
        this.loading = true

        const { addresses, contacts, bankAccounts, pixes, telephonesApi } = this.attributes

        const { birthdate, gender, stateRegistrationType, telephones } = this.person

        const notInTelephonesForm = tApi => telephones.findIndex(t => t.id === tApi.id) < 0
        const telephonesDeleted = telephonesApi
          .filter(tApi => notInTelephonesForm(tApi))
          .map(tDeleted => ({ ...tDeleted, isDeleted: true }))

        const body = {
          ...this.person,
          person: this.person.personType,
          birthdate,
          gender: gender || null,
          stateRegistrationType: stateRegistrationType || null,
          telephones: [...telephones, ...telephonesDeleted],
          addresses,
          contacts,
          bankAccounts,
          pixes,
        }

        const result = await this.$http({
          url: '/api/customers',
          method: this.isEdit ? 'PUT' : 'POST',
          data: body,
        })

        this.customerSaved = result.data

        this.showSuccess({
          message: this.$t(`${this.isEdit ? 'Atualização' : 'Inclusão'} realizada com sucesso`),
        })

        await this.processDocuments(this.customerSaved)
        await this.executePosSaveActions()
        this.cleanState()
        this.navigateBack()
      } catch (err) {
        this.showError({ error: err })
      } finally {
        this.loading = false
      }
    },

    async processDocuments(data) {
      let hasFileError = false
      try {
        await this.uploadDocuments(data?.id)
      } catch (uploadError) {
        this.showError({
          title: this.$t('Ocorreu um erro ao fazer upload dos anexos'),
          error: uploadError,
        })
        hasFileError = true
      }

      try {
        await this.deleteDocuments(data?.id)
      } catch (deleteDocumentError) {
        this.showError({
          title: this.$t('Ocorreu um erro ao deletar os anexos'),
          error: deleteDocumentError,
        })
        hasFileError = true
      }

      if (!hasFileError && (this.stFiles.some(f => !f.id) || this.stFilesDeleted.length > 0)) {
        this.showSuccess({ message: this.$t('Anexos salvos com sucesso.') })
      }
    },

    async removeFile(file) {
      this.deleteLocalFile(file)
    },

    onCancel() {
      this.cleanState()
      this.navigateBack()
    },

    async updateOrderMaintainCustomer() {
      await this.stOrderMaintainFetchCustomerById({ customerId: this.customerSaved.id })
    },

    async executePosSaveActions() {
      switch (this.callbackRouteName) {
        case 'order-add':
          await this.updateOrderMaintainCustomer()
          break
        default:
          break
      }
    },

    navigateBack() {
      this.$router.push({ name: this.callbackRouteName })
      this.callbackRouteName = 'client-list'
      this.customerSaved = null
    },
  },
}
</script>
